// BlogAndTistory.js
import '../css/BlogAndTistory.css';
import React from 'react';
import BlogSlider from './BlogSlider';


const BlogAndTistory = () => {
    const blogImages = [
        '/img/blogImageSlider/blogImageSlider1.jpg',
        '/img/blogImageSlider/blogImageSlider2.jpg',
    ];

    const blogImageLinks = [
        'https://blog.naver.com/kujunghousing/223272846239',
        'https://blog.naver.com/kujunghousing/223272846239',
    ];

    const tistoryImages = [
        '/img/tistoryImageSlider/tistoryImageSlider1.jpg',
        '/img/tistoryImageSlider/tistoryImageSlider1.jpg',
    ];

    const tistoryImageLinks = [
        'https://with-your-maru.tistory.com/2',
        'https://with-your-maru.tistory.com/2',
    ];

    const BlogButtonClick = () => {
        window.location.href = 'https://blog.naver.com/kujunghousing';
    };

    const TButtonClick = () => {
        window.location.href = 'https://with-your-maru.tistory.com/';
    };

    return (
        <div>
            <div className="blog-rectangle">
                <div className="blog-slider-container">
                    <BlogSlider images={blogImages} imageLinks={blogImageLinks} />
                </div>

                <div className='blog-text-container'>
                    <img className='Blog-icon' src="png/blog.png" alt="blogicon" />
                    <div className="blog-text">with BLOG</div>
                    <div className="blog-btn-container">
                        <button className="blog-btn" onClick={BlogButtonClick}>
                            + VIEW MORE
                        </button>
                    </div>
                </div>
            </div>

            <div className="Tistory-rectangle">
                <div className="Tistory-slider-container">
                    <BlogSlider images={tistoryImages} imageLinks={tistoryImageLinks} />
                </div>

                <div className='Tistory-text-container'>
                    <img className='tistory-icon' src="png/tistory.png" alt="tistoryicon" />
                    <div className="tistory-text">with T-story</div>
                    <div className="tistory-btn-container">
                        <button className="tistory-btn" onClick={TButtonClick}>
                            + VIEW MORE
                        </button>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default BlogAndTistory;
