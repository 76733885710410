import React, { useState, useEffect } from 'react';
import '../css/ImageSlider.css'; // 슬라이더 스타일 파일

const ImageSlider = () => {
    const sliderImages = [
        { image: '/img/ImageSlider/ImageSlider1.jpg', description: ['[프랜티안] 화이트 오크', '서울시 송파구 아파트'] },
        { image: '/img/ImageSlider/ImageSlider2.jpg', description: ['설명2-1', '설명2-2'] },
        { image: '/img/ImageSlider/ImageSlider3.jpg', description: ['설명3-1', '설명3-2'] },
        { image: '/img/ImageSlider/ImageSlider4.jpg', description: ['설명4-1', '설명4-2'] },
        { image: '/img/ImageSlider/ImageSlider5.jpg', description: ['설명5-1', '설명5-2'] },
        { image: '/img/ImageSlider/ImageSlider6.jpg', description: ['설명6-1', '설명6-2'] },
        { image: '/img/ImageSlider/ImageSlider7.jpg', description: ['설명7-1', '설명7-2'] },
        { image: '/img/ImageSlider/ImageSlider8.jpg', description: ['설명8-1', '설명8-2'] },
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const slideInterval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 4) % sliderImages.length); // 4개씩 슬라이드 되도록 수정
        }, 7000);

        return () => {
            clearInterval(slideInterval);
        };
    }, [sliderImages.length]);

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? sliderImages.length - 4 : prevIndex - 4)); // 이전 슬라이드 함수 수정
    };

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 4) % sliderImages.length); // 다음 슬라이드 함수 수정
    };

    const handleViewMore = () => {
        // View More 버튼을 클릭했을 때의 동작을 정의합니다.
        // 예를 들어, 추가적인 액션을 수행하거나 해당 페이지로 이동하는 등의 동작을 구현할 수 있습니다.
        console.log("View More 버튼 클릭");
    };

    // 설명 두 번째 줄에 위치시킬 아이콘 이미지 경로
    const iconImagePath = '/png/home.png';

    // 아이콘 크기를 직접 조정
    const iconStyle = {
        width: '20px',
        height: '20px',

        marginRight: '5px' // 오른쪽 여백 추가
    };

    return (
        <div className="image-slider-container">
            <div className="text-block">with 시공 사례</div>
            <div className="slider-container">
                <div className="slider" style={{ transform: `translateX(-${(currentIndex * (100 / sliderImages.length))}%)` }}>
                    {sliderImages.map((item, index) => (
                        <div key={index} className="slide">
                            <img src={item.image} alt={`Image ${index + 1}`} />
                            <div className="image-description">
                                <p>{item.description[0]}</p>
                                <div className="image-description-container">
                                    <img src={iconImagePath} alt="Icon" style={iconStyle} />
                                    <p> {item.description[1]}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <button className="ImgSlider-view-more-btn" onClick={handleViewMore}> + VIEW MORE</button>
        </div>
    );
};

export default ImageSlider;
