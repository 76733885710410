import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/BestProduct.css'; // 푸터 스타일 파일 임포트

const BestProduct = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/maru1');
    };

    return (
        <div className="moveDown">
            <div style={{ textAlign: 'center' }}>
                <h1>Best Product</h1>
                <div className="product-container-wrapper">
                    {/* 이미지를 사용하는 부분 */}
                    {/* 이미지 경로를 수정 */}
                    <div className="product-container" onClick={handleClick}>
                        <img
                            src={process.env.PUBLIC_URL + '/img/ImageSlider/ImageSlider1.jpg'}
                            alt="Product 1"
                            style={{ width: '400px', height: '360px', margin: '10px', cursor: 'pointer' }}
                        />
                        <p>Product 1 설명</p>
                    </div>
                    {/* 이미지 경로를 수정 */}
                    <div className="product-container" onClick={handleClick}>
                        <img
                            src={process.env.PUBLIC_URL + '/img/ImageSlider/ImageSlider2.jpg'}
                            alt="Product 2"
                            style={{ width: '400px', height: '360px', margin: '10px', cursor: 'pointer' }}
                        />
                        <p>Product 2 설명</p>
                    </div>
                    {/* 이미지 경로를 수정 */}
                    <div className="product-container" onClick={handleClick}>
                        <img
                            src={process.env.PUBLIC_URL + '/img/ImageSlider/ImageSlider3.jpg'}
                            alt="Product 3"
                            style={{ width: '400px', height: '360px', margin: '10px', cursor: 'pointer' }}
                        />
                        <p>Product 3 설명</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BestProduct;
