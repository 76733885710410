/// Maru2.js

import React, { useState } from 'react';
import '../css/Maru2.css';

const Maru2 = () => {
    const items = [
        { name: '아이템 1', imageUrl: '/img/maru1/maru1.jpg', caption: '<p class="caption1">첫 번째 아이템 설명</p>' },
        { name: '아이템 2', imageUrl: '/img/maru1/maru2.jpg', caption: '<p class="caption1">두 번째 아이템 설명</p>' },
        { name: '아이템 3', imageUrl: '/img/maru1/maru3.jpg', caption: '<p class="caption1">세 번째 아이템 설명</p>' },
        { name: '아이템 4', imageUrl: '/img/maru1/maru4.jpg', caption: '<p class="caption1">네 번째 아이템 설명</p>' },
        { name: '아이템 5', imageUrl: '/img/maru1/maru5.jpg', caption: '<p class="caption1">다섯 번째 아이템 설명</p>' },
        { name: '아이템 6', imageUrl: '/img/maru1/maru6.jpg', caption: '<p class="caption1">여섯 번째 아이템 설명</p>' },
        { name: '아이템 7', imageUrl: '/img/maru1/maru7.jpg', caption: '<p class="caption1">일곱 번째 아이템 설명</p>' },
        { name: '아이템 8', imageUrl: '/img/maru1/maru8.jpg', caption: '<p class="caption1">여덟 번째 아이템 설명</p>' },
    ];

    const [selectedPhoto, setSelectedPhoto] = useState(null);

    const openModal = (photoName) => {
        setSelectedPhoto(photoName);
    };

    const closeModal = () => {
        setSelectedPhoto(null);
    };

    return (
        <div className="maru2-container">
            <div className='maru2-text'>프렌티안 220</div>
            <div className='maru2-text2'>설명</div>
            <hr className="maru-line" />
            <div className="photo-grid">
                {items.map((item) => (
                    <div
                        key={item.name}
                        className="photo-item"
                        onClick={() => openModal(item.name)}
                    >
                        <img src={item.imageUrl} alt={item.name} />
                        <div className="photo-description" dangerouslySetInnerHTML={{ __html: item.caption }} />
                    </div>
                ))}
            </div>

            {selectedPhoto !== null && (
                <div className="modal" onClick={closeModal}>
                    <div className="modal-content">
                        <img
                            src={items.find((item) => item.name === selectedPhoto).imageUrl}
                            alt={selectedPhoto}
                        />
                        <button onClick={closeModal}>닫기</button>
                    </div>
                </div>
            )}
            <div className="GreenGuard-container">
                <img src="/img/GreenGuard.png" alt="GreenGuard" className="GreenGuard" />
                <div className="GreenGuard_text">
                    <p>위드마루 프랜티안 제품은 친환경을 기본으로 모든 상품을 제작합니다.</p>
                    <p>국내에서는 원목마루 중에서 처음으로 안전 규격 개발 인증기관 UL이 환경 부문에서 운영하는 </p>
                    <p> 친환경 인증제도 그린가드의 골드 등급 인증을 받았습니다.</p>
                    <p>그린가드 골드 인증은 실내 공기의 품질 개선 및 화학물질의 노출을 줄임으로써 가족의 건강한 삶의 질 추구를 위해</p>
                    <p>  2001년에 설립된 기관으로서 엄격한 화학물질 배출 기준을 충족해야만 인증을 획득할 수 있습니다.</p>
                    <p>안전성 검사는 무척이나 까다롭다고 알려져 있습니다.</p>
                </div>
                <img src="/img/Warning.jpg" alt="GreenGuard" className="Warning" />
            </div>
        </div>
    );
};

export default Maru2;
