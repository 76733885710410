// App.js

import React, { useEffect, useState } from 'react';
import './App.css';
import { Routes, Route, useLocation } from 'react-router-dom'; // BrowserRouter 제거
import Header from './js/Header';
import MainImage from './js/MainImage';
import Footer from './js/Footer';
import BestProduct from './js/BestProduct';
import BlogAndTistory from './js/BlogAndTistory';
import ImageSlider from './js/ImageSlider';
import InstagramFeed from './js/InstagramFeed';
import Exhibit from './js/Exhibit';
import Company from './js/Company';
import Maru1 from './js/Maru1'; // Maru1 컴포넌트를 import
import Maru2 from './js/Maru2'; // Maru2 컴포넌트를 import
import Maru3 from './js/Maru3'; // Maru2 컴포넌트를 import

function App() {
  // 웹 폰트 로드 및 글꼴 변경
  useEffect(() => {
    const link = document.createElement('link');
    link.href = 'https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap';
    link.rel = 'stylesheet';
    document.head.appendChild(link);

    const link2 = document.createElement('link');
    link2.href = 'https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap';
    link2.rel = 'stylesheet';
    document.head.appendChild(link2);
  }, []);

  // 현재 경로(location)를 가져옵니다.
  const location = useLocation();

  // 전시장 페이지 경로 확인
  const isExhibitPage = location.pathname === '/Exhibit';

  return (
    <div className="App">
      <Header />
      <Routes>
        {/* 전시장 페이지 경로로 이동할 때만 Exhibit 컴포넌트를 렌더링 */}
        {isExhibitPage && <Route path="/Exhibit" element={<Exhibit />} />}
        {/* maru1 페이지 경로로 이동할 때만 Maru1 컴포넌트를 렌더링 */}
        {location.pathname === '/maru1' && <Route path="/maru1" element={<Maru1 />} />}
        {location.pathname === '/maru2' && <Route path="/maru2" element={<Maru2 />} />}
        {location.pathname === '/maru3' && <Route path="/maru3" element={<Maru3 />} />}
      </Routes>

      {/*메인페이지 때만 나머지 컴포넌트를 렌더링 */}
      {location.pathname === '/' && (
        <>
          <MainImage />
          <BestProduct />
          <ImageSlider />
          <BlogAndTistory />
          <InstagramFeed />
        </>
      )}
      {/* 푸터는 항상 렌더링 */}
      <Footer />
    </div>
  );
}

export default App;
