// BlogSlider.js
import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import '../css/BlogSlider.css';

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const BlogSlider = ({ images, imageLinks, autoplaySpeed = 2000 }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: autoplaySpeed,
        beforeChange: (current, next) => setCurrentSlide(next),
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prev) => (prev + 1) % images.length);
        }, autoplaySpeed);

        return () => clearInterval(interval);
    }, [images, autoplaySpeed]);

    return (
        <div style={{ width: '550px', height: '250px', margin: '0 auto', position: 'relative' }}>
            <Slider {...settings}>
                {images.map((image, index) => (
                    <a key={index} href={imageLinks[index]} target="_blank" rel="noopener noreferrer">
                        <div style={{ width: '100%', height: '100%' }}>
                            <img
                                src={image}
                                alt={`slide-${index}`}
                                style={{ width: '550px', height: '250px', objectFit: 'cover' }}
                            />
                        </div>
                    </a>
                ))}
            </Slider>
            <div
                style={{
                    position: 'absolute',
                    bottom: '10px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                {images.map((_, index) => (
                    <span
                        key={index}
                        style={{
                            display: 'inline-block',
                            // width: '8px', 사진 안에 있는 순서 원형 
                            // height: '8px',
                            borderRadius: '50%',
                            background: index === currentSlide ? '#000' : '#ccc',
                            margin: '0 4px',
                            cursor: 'pointer',
                        }}
                    ></span>
                ))}
            </div>
        </div>
    );
};

export default BlogSlider;
