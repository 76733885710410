import React, { useEffect } from 'react';
import '../css/MainImage.css'; // 스타일 파일 임포트

<link href='https://fonts.googleapis.com/css?family=Montserrat:700|Pinyon+Script' rel='stylesheet' type='text/css'>


</link>
const MainImage = () => {

  useEffect(() => {
    // 페이지 로드 시 실행되는 함수
    const mainWithMaru = document.querySelector('.main_withmaru');
    const yourElement = document.querySelector('.Your');

    // .main_withmaru와 .Your에 초기화를 위한 클래스 'initial' 추가
    mainWithMaru.classList.add('initial');
    yourElement.classList.add('initial');
  }, []);


  return (
    <div className="container">
      <div className="image-container">
        <div className="image-background">
          <img
            src="/img/main_img.jpg"
            alt="Main_Image"
            className="MainImage"
          />
        </div>
        {/* <div className="shape-container">
          <div className="semicircle"></div>
          <div className="rectangle"></div>
        </div> */}
        <div className="e">
          <div className="main_withmaru">위드원목마루</div>
          <div className="Your">편안한 공간의 시작점</div>
        </div>
        {/* <div className="comfort-space">편안한 공간의 시작점</div> */}
      </div>
    </div>
  );
};

export default MainImage;
