// Exhibit.js

import React from 'react';
import { Link } from 'react-router-dom';
import '../css/Exhibit.css'; // Exhibit 페이지에 대한 CSS 파일 임포트
import Footer from '../js/Footer'; // 기존 푸터 컴포넌트를 임포트

//import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

{/* 
// Google 지도 컴포넌트 생성
const MapWithAMarker = withScriptjs(withGoogleMap(props => (
    <GoogleMap
        defaultZoom={15} // 초기 확대 수준 설정
        defaultCenter={{ lat: 37.656996, lng: 127.190980 }} // 매장의 위도 및 경도 설정
    >
        <Marker
            position={{ lat: 37.656996, lng: 127.190980 }} // 매장 위치 설정
            title="매장 이름" // 매장 이름 설정
        />
    </GoogleMap>
));*/}


const Exhibit = () => {
    return (
        <div>
            <div className="Exhibit_container">
                <div className="Exhibit_image-container">
                    <div className="Exhibit_shape-container">
                        <img src="/img/Exhibit.jpg" alt="Exhibit_img" className="Exhibit_Semicircle" />
                    </div>
                    <div className="Exhibit_Withmaru">withmaru</div>
                    <div className="Exhibit_comfort_space">편안한 공간의 시작점</div>
                </div>
                <div className="Exhibit_text">남양주시 일패동에 위치한 전시장</div>
                <div className="Exhibit_text2">대충 전시장 오라는 감성적인 슬로건 <p>ex 내눈으로 직접 확인하니까 블라블라</p></div>
                <div className="Exhibit_text3">운영시간</div>
                <div className="Exhibit_text4">평일 9:30 - 17:30 <p>토요일 9:30-13:30</p> <p>일요일 휴무</p> </div>
                <div className="Exhibit_text5">전시장 위치</div>
                <div className="Exhibit_text6">경기 남양주시 양정로 106 구정하우징</div>
            </div>
            {/* 구글 지도는 api 필요없음 구글맵에서 자체 html 코드 제공 */}
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3160.533100451768!2d127.18251102676554!3d37.61314615425364!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357cb752f1394527%3A0x1fb1c072096b52f!2z6rWs7KCV7ZWY7Jqw7KeV!5e0!3m2!1sko!2skr!4v1703579259258!5m2!1sko!2skr"
                width="900px;"
                height="800px;"
                style={{ position: 'absolute', top: 2800, left: '50%', transform: 'translateX(-50%)' }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
            ></iframe>

            {/*   Google 지도 컴포넌트를 표시 
            <MapWithAMarker
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&v=3.exp&libraries=geometry,drawing,places`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `400px` }} /> /* 지도의 높이 조정 
                mapElement={<div style={{ height: `100%` }} />}
            />
            */}
            <div className="Exhibit_text7">견적 및 시공 상담</div>
            <div className="Exhibit_text8"><p>031-576-4003</p> <p>kujunghousing@naver.com</p></div>
        </div>
    );
};


export default Exhibit;
