import React, { useEffect, useState, useRef } from 'react';
import '../css/InstagramFeed.css'; // 슬라이더 스타일 파일


const InstagramFeed = () => {
    const [posts, setPosts] = useState([]);
    const [startIndex, setStartIndex] = useState(0);
    const gridRef = useRef(null);

    useEffect(() => {
        // Instagram API를 통해 최신 게시물 가져오기
        const fetchInstagramPosts = async () => {
            try {
                const response = await fetch(
                    'https://graph.instagram.com/v12.0/me/media?fields=id,caption,media_type,media_url,thumbnail_url,permalink,timestamp&limit=9&access_token=IGQWROZAm9aRHV0cVdFUEpCY3hiMVlXSVhJYmJZAWEtRUTQtbnQxdVgwd0Q4YnRjRmhZAZA1dtTm03eXZA0X05veGd1MDJGaE1KT0hRWDFhSENvb2U0TG9zLVZACRmVYVm5JdHh4cE5FeTlVWUZAXY2JyY0JoWk1faU1jQjQZD'
                );
                const data = await response.json();
                setPosts(data.data);
            } catch (error) {
                console.error('Error fetching Instagram posts:', error);
            }
        };

        fetchInstagramPosts();
    }, []);

    const openInstagramProfile = () => {
        window.location.assign('https://www.instagram.com/kujunghousing/');
    };

    const openInstagramPost = (permalink) => {
        window.location.assign(permalink);
    };

    const scrollLeft = () => {
        setStartIndex(Math.max(0, startIndex - 5)); // 왼쪽 화살표를 클릭하면 시작 인덱스를 감소시킴
    };

    const scrollRight = () => {
        setStartIndex(Math.min(posts.length - 5, startIndex + 5)); // 오른쪽 화살표를 클릭하면 시작 인덱스를 증가시킴
    };

    return (
        <div className='insta-container'>
            <div className="instagram-container">
                <div className='instagram-header'>
                    <div className='insta-text'>INSTAGRAM</div>
                    <img className='insta-icon' src="png/instagram2.png" alt="Instagram" />
                </div>
                <div className='insta-text2'>위드마루의 소식을 빠르게 받아보세요</div>
                <div className="instagram-grid" ref={gridRef}>
                    {posts && posts.length > 0 ? (
                        posts.slice(startIndex, startIndex + 5).map((post) => (
                            <div
                                key={post.id}
                                className="instagram-post"
                                onClick={() => openInstagramPost(post.permalink)}
                                style={{ cursor: 'pointer' }}
                            >
                                <img
                                    src={post.media_url}
                                    alt={post.caption}
                                />
                                {/* <p>{post.caption}</p> */}
                            </div>
                        ))
                    ) : (
                        <div>Loading...</div>
                    )}
                </div>
                <button className="view-more-button" onClick={openInstagramProfile}>
                    + View More
                </button>
            </div>
            <button className="insta-left-button" onClick={scrollLeft}>
                <img src="/png/arrow_left.png" alt="Previous" />
            </button>
            <button className="insta-right-button" onClick={scrollRight}>
                <img src="/png/arrow_right.png" alt="Next" />
            </button>
        </div>
    );
};

export default InstagramFeed;
