// Footer.js
import React from 'react';
import '../css/Footer.css'; // 푸터 스타일 파일 임포트

// window.addEventListener('scroll', function () {
//     const footer = document.querySelector('.footer-background');
//     const scrollHeight = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;

//     if (scrollHeight + window.innerHeight >= document.documentElement.scrollHeight) {
//         footer.style.bottom = '0'; // 스크롤이 맨 아래에 도달하면 푸터를 보이게 함
//     } else {
//         footer.style.bottom = '-260px'; // 스크롤이 맨 아래가 아닐 때 푸터를 숨김
//     }
// });

const Footer = () => {
    return (
        <div>
            <div className='footer-background'>
                <div className='footer-left-container'>
                    <div className="footer-text-Phone">견적상담 및 시공문의</div>
                    <div className="footer-line-Width"></div>
                    <div className="footer-text-Phone2">031-576-4003</div>
                    <div className="footer-line-Height2"></div>
                    <div className="footer-text-Consultation">상담 가능 시간</div>
                    <div className="footer-text-Consultation2">월-금 : 09:30 ~ 17:30 <p>(주말 및 공휴일 휴무)</p></div>
                </div>

                <div className="footer-line-Height"></div>
                <div className='footer-right-container'>
                    <div className="footer-withmaru-text"> with maru </div>
                    <div className="footer-Privacy"> 개인정보 처리 방침  | </div>
                    <div className="footer-Copyright"> 저작권 안내 </div>
                    <div className="footer-Address">
                        전시장 주소 : 경기 남양주시 양정로 106 구정하우징<p></p>
                        사업자 등록번호 : 195-03-01875  |  대표자 : 김은화<p></p>
                        대표전화 : 031-576-4003
                    </div>
                    <div className="footer-copyright">
                        ⓒCopyright 위드마루. All rights reserved. 상품 사진을 포함한 모든 컨텐츠는 저작권법 제 98조에 의거해 보호를 받고 있습니다.
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Footer;